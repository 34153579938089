import ahoy from "ahoy.js";
import {ApplicationController as ApplicationControllerStimulusUse, useApplication} from 'stimulus-use'

export default class extends ApplicationControllerStimulusUse {
  initialize(){}

  connect() {
    useApplication(this)
    console.log('[ApplicationController connect]')
  }

  handleError = (error) => {
    const context = {
      controller: this.identifier,
      user_id: this.userId,
    };
    console.log('handleError', context, error)
    // this.application.handleError(
    //   error,
    //   `Error in controller: ${this.identifier}`,
    //   context
    // );
  };
}